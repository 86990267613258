/* Base styles for the sidebar */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  /* Start off-canvas */
  width: 250px;
  height: 100vh;
  background-color: #343a40;
  transition: left 0.3s ease;
  z-index: 1000;
  overflow-y: auto;
}

.sidebar.open {
  left: 0;
  /* Slide in when open */
}

.close-sidebar,
.shrink-sidebar {
  background: none;
  border: none;
  cursor: pointer;
}

.close-sidebar {
  position: absolute;
  top: 10px;
  right: 10px;
  color: white;
  font-size: 24px;
}

.shrink-sidebar {
  display: none;
}

.shrink-sidebar {
  position: absolute;
  top: 20px;
  left: 25px;
}

.sidebar-header {
  display: flex;
  align-items: center;
}

.sidebar-logo {
  padding: 20px;
  text-align: center;
}

.Logopng {
  height: 40px;
  margin-left: 50px;
  width: 149px;
  background-color: white;
  padding: 10px;
  border-radius: 10px;
  cursor: pointer;
}

.nav-link {
  padding: 10px 15px;
  color: #7c8db5;
  transition: background-color 0.3s, color 0.3s;
}

.nav-link:hover,
.nav-link.active {
  color: #347ae2;
}

.icon {
  width: 16px;
  height: 16px;
}

.arrowClose {
  width: 51px;
  height: 0;
  stroke-width: 2px;
  stroke: #fff;
}

@media (min-width: 768px) {
  .sidebar {
    left: 0;
    width: 300px;
  }

  .main-content {
    margin-left: 300px;
  }

  .close-sidebar {
    display: none;
  }
}

@media (max-width: 767px) {

  /* Sidebar covers the screen on small devices */
  .sidebar {
    width: 100%;
    left: -100%;
  }

  .sidebar.open {
    left: 0;
  }

  .nav-link {
    text-align: center;
    padding: 15px;
  }

  .sidebar-logo {
    margin: auto;
    padding: 10px;
  }

  .Logopng {
    margin-left: 0;
    width: 120px;
  }

  .close-sidebar {
    font-size: 18px;
    top: 5px;
    right: 5px;
  }

  .shrink-sidebar {
    display: block;
  }

  .hamburger-menu {
    display: block;
    /* Show hamburger menu on small screens */
    background: none;
    /* Remove background */
    border: none;
    /* Remove border */
    cursor: pointer;
    /* Change cursor to pointer */
  }
}

/* Custom styles for buttons and responsive tables */
.btn-multiline {
  white-space: normal;
  line-height: 1.2;
}

.btn-primary {
  border-radius: 0;
  background: #347ae2;
}

.btn-light {
  flex-shrink: 0;
  border-radius: 0;
}

.tab-scroll {
  overflow-x: auto;
  white-space: nowrap;
}

.table-responsive {
  overflow-x: auto;
}

/* Accessibility Improvements */
.nav-link:focus,
.btn-primary:focus {
  /* outline: 2px solid #347ae2;
  outline-offset: 2px; */
}