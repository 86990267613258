.loader {
  --dim: 3rem;
  width: var(--dim);
  height: var(--dim);
  position: relative;
  animation: spin988 2s linear infinite;
  margin-top: 200px;
  margin-left: 400px;
  margin-bottom: 200px;
}

.loader .circle {
  --color: #333;
  --dim: 1.2rem;
  width: var(--dim);
  height: var(--dim);
  background-color: var(--color);
  border-radius: 50%;
  position: absolute;
}

.loader .circle:nth-child(1) {
  top: 0;
  left: 0;
}

.loader .circle:nth-child(2) {
  top: 0;
  right: 0;
}

.loader .circle:nth-child(3) {
  bottom: 0;
  left: 0;
}

.loader .circle:nth-child(4) {
  bottom: 0;
  right: 0;
}

@keyframes spin988 {
  0% {
    transform: scale(1) rotate(0);
  }

  20%,
  25% {
    transform: scale(1.3) rotate(90deg);
  }

  45%,
  50% {
    transform: scale(1) rotate(180deg);
  }

  70%,
  75% {
    transform: scale(1.3) rotate(270deg);
  }

  95%,
  100% {
    transform: scale(1) rotate(360deg);
  }
}

.btn-cyan {
  background-color: #40b1ca;
  border-color: #40b1ca;
  border-radius: 0.5rem;
}

.btn-cyan:hover {
  background-color: darkcyan;
  border-color: darkcyan;
}

.btn-blue {
  background-color: #4080ca;
  border-color: #4080ca;
  border-radius: 0.5rem;
}

.btn-blue:hover {
  background-color: darkblue;
  border-color: darkblue;
}
.btn-green {
  background-color: #40ca64;
  border-color: #40ca64;
  border-radius: 0.5rem;
}
.btn-green:hover {
  background-color: #1b8d3a;
  border-color: #1b8d3a;
  border-radius: 0.5rem;
}
